import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import { LoadingOverlay } from '@bubbles-ui/components';
import "./global.css";

// Plugins imports
import { Provider as CommonGlobalProvider } from '@common/../globalContext';
import { Provider as LayoutGlobalProvider } from '@layout/../globalContext';
const AiAssistantPrivate = loadable(() => pMinDelay(import('@ai-assistant/../Private'), 500));
const AcademicCalendarPrivate = loadable(() => pMinDelay(import('@academic-calendar/../Private'), 500));
const AcademicPortfolioPrivate = loadable(() => pMinDelay(import('@academic-portfolio/../Private'), 500));
import { Provider as AcademicPortfolioGlobalProvider } from '@academic-portfolio/../globalContext';
const AdminPublic = loadable(() => pMinDelay(import('@admin/../Public'), 500));
const AdminPrivate = loadable(() => pMinDelay(import('@admin/../Private'), 500));
const AssignablesPrivate = loadable(() => pMinDelay(import('@assignables/../Private'), 500));
const AttendanceControlPrivate = loadable(() => pMinDelay(import('@attendance-control/../Private'), 500));
const BoardMessagesPrivate = loadable(() => pMinDelay(import('@board-messages/../Private'), 500));
const CalendarPrivate = loadable(() => pMinDelay(import('@calendar/../Private'), 500));
const ClientManagerPrivate = loadable(() => pMinDelay(import('@client-manager/../Private'), 500));
import { Provider as ClientManagerGlobalProvider } from '@client-manager/../globalContext';
const ComunicaPrivate = loadable(() => pMinDelay(import('@comunica/../Private'), 500));
import { Provider as ComunicaGlobalProvider } from '@comunica/../globalContext';
const CommunitiesPrivate = loadable(() => pMinDelay(import('@communities/../Private'), 500));
const ContentCreatorPrivate = loadable(() => pMinDelay(import('@content-creator/../Private'), 500));
const ContentDeveloperPrivate = loadable(() => pMinDelay(import('@content-developer/../Private'), 500));
const CurriculumPrivate = loadable(() => pMinDelay(import('@curriculum/../Private'), 500));
const DashboardPrivate = loadable(() => pMinDelay(import('@dashboard/../Private'), 500));
const EmailsPrivate = loadable(() => pMinDelay(import('@emails/../Private'), 500));
const FamiliesPrivate = loadable(() => pMinDelay(import('@families/../Private'), 500));
const FeedbackPrivate = loadable(() => pMinDelay(import('@feedback/../Private'), 500));
const FundaePrivate = loadable(() => pMinDelay(import('@fundae/../Private'), 500));
const GradesPrivate = loadable(() => pMinDelay(import('@grades/../Private'), 500));
const LearningPathsPrivate = loadable(() => pMinDelay(import('@learning-paths/../Private'), 500));
const LeebraryPrivate = loadable(() => pMinDelay(import('@leebrary/../Private'), 500));
const LeebraryProtected = loadable(() => pMinDelay(import('@leebrary/../Protected'), 500));
import { Provider as LeebraryGlobalProvider } from '@leebrary/../globalContext';
import { Provider as MenuBuilderGlobalProvider } from '@menu-builder/../globalContext';
const MqttAwsIotPrivate = loadable(() => pMinDelay(import('@mqtt-aws-iot/../Private'), 500));
import { Provider as MqttAwsIotGlobalProvider } from '@mqtt-aws-iot/../globalContext';
import { Provider as MqttSocketIoGlobalProvider } from '@mqtt-socket-io/../globalContext';
const MsGraphPrivate = loadable(() => pMinDelay(import('@ms-graph/../Private'), 500));
const ScoresPrivate = loadable(() => pMinDelay(import('@scores/../Private'), 500));
const SessionsPrivate = loadable(() => pMinDelay(import('@sessions/../Private'), 500));
const ScormPrivate = loadable(() => pMinDelay(import('@scorm/../Private'), 500));
const TasksPrivate = loadable(() => pMinDelay(import('@tasks/../Private'), 500));
const TestsPrivate = loadable(() => pMinDelay(import('@tests/../Private'), 500));
const TimetablePublic = loadable(() => pMinDelay(import('@timetable/../Public'), 500));
const UsersCognitoPublic = loadable(() => pMinDelay(import('@users-cognito/../Public'), 500));
const UsersCognitoProtected = loadable(() => pMinDelay(import('@users-cognito/../Protected'), 500));
import { Provider as UsersCognitoLocalProvider } from '@users-cognito/../localContext';
const UsersPublic = loadable(() => pMinDelay(import('@users/../Public'), 500));
const UsersPrivate = loadable(() => pMinDelay(import('@users/../Private'), 500));
const UsersProtected = loadable(() => pMinDelay(import('@users/../Protected'), 500));
import { Provider as UsersGlobalProvider } from '@users/../globalContext';
import { Provider as XapiGlobalProvider } from '@xapi/../globalContext';

function App() {
  // Plugins hooks
  return (
    <Router>
        <CommonGlobalProvider>
        <LayoutGlobalProvider>
        <AcademicPortfolioGlobalProvider>
        <ClientManagerGlobalProvider>
        <ComunicaGlobalProvider>
        <LeebraryGlobalProvider>
        <MenuBuilderGlobalProvider>
        <MqttAwsIotGlobalProvider>
        <MqttSocketIoGlobalProvider>
        <UsersGlobalProvider>
        <XapiGlobalProvider>
            <Switch>
              {/* Define each plugin route */}




                <Route path="/private/ai-assistant">
                                  <AiAssistantPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/academic-calendar">
                                  <AcademicCalendarPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/academic-portfolio">
                                  <AcademicPortfolioPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/admin">


                  <AdminPublic fallback={<LoadingOverlay visible />} />
                </Route>
                <Route path="/private/admin">
                                  <AdminPrivate fallback={<LoadingOverlay visible />} />
                </Route>




                <Route path="/private/assignables">
                                  <AssignablesPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/attendance-control">
                                  <AttendanceControlPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/board-messages">
                                  <BoardMessagesPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/calendar">
                                  <CalendarPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/client-manager">
                                  <ClientManagerPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/comunica">
                                  <ComunicaPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/communities">
                                  <CommunitiesPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/content-creator">
                                  <ContentCreatorPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/content-developer">
                                  <ContentDeveloperPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/curriculum">
                                  <CurriculumPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/dashboard">
                                  <DashboardPrivate fallback={<LoadingOverlay visible />} />
                </Route>








                <Route path="/private/emails">
                                  <EmailsPrivate fallback={<LoadingOverlay visible />} />
                </Route>






                <Route path="/private/families">
                                  <FamiliesPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/feedback">
                                  <FeedbackPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/fundae">
                                  <FundaePrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/grades">
                                  <GradesPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/learning-paths">
                                  <LearningPathsPrivate fallback={<LoadingOverlay visible />} />
                </Route>




                <Route path="/private/leebrary">
                                  <LeebraryPrivate fallback={<LoadingOverlay visible />} />
                </Route>
                <Route path="/protected/leebrary">
                                  <LeebraryProtected fallback={<LoadingOverlay visible />} />
                </Route>






                <Route path="/private/mqtt-aws-iot">
                                  <MqttAwsIotPrivate fallback={<LoadingOverlay visible />} />
                </Route>




                <Route path="/private/ms-graph">
                                  <MsGraphPrivate fallback={<LoadingOverlay visible />} />
                </Route>






                <Route path="/private/scores">
                                  <ScoresPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/sessions">
                                  <SessionsPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/scorm">
                                  <ScormPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/tasks">
                                  <TasksPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/private/tests">
                                  <TestsPrivate fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/timetable">


                  <TimetablePublic fallback={<LoadingOverlay visible />} />
                </Route>


                <Route path="/users-cognito">


              <UsersCognitoLocalProvider>
                  <UsersCognitoPublic fallback={<LoadingOverlay visible />} />
              </UsersCognitoLocalProvider>
                </Route>
                <Route path="/protected/users-cognito">
                              <UsersCognitoLocalProvider>
                  <UsersCognitoProtected fallback={<LoadingOverlay visible />} />
              </UsersCognitoLocalProvider>
                </Route>


                <Route path="/users">


                  <UsersPublic fallback={<LoadingOverlay visible />} />
                </Route>
                <Route path="/private/users">
                                  <UsersPrivate fallback={<LoadingOverlay visible />} />
                </Route>
                <Route path="/protected/users">
                                  <UsersProtected fallback={<LoadingOverlay visible />} />
                </Route>






              <Route path="/">
                   <Redirect to={`/admin`} />
              </Route>
            </Switch>
        </XapiGlobalProvider>
        </UsersGlobalProvider>
        </MqttSocketIoGlobalProvider>
        </MqttAwsIotGlobalProvider>
        </MenuBuilderGlobalProvider>
        </LeebraryGlobalProvider>
        </ComunicaGlobalProvider>
        </ClientManagerGlobalProvider>
        </AcademicPortfolioGlobalProvider>
        </LayoutGlobalProvider>
        </CommonGlobalProvider>
    </Router>
  );
}

export default App;
